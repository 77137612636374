<template>
  <div class="home">
    <el-main>
      <el-container>
        <div class="container">
          <h1 class="title">DNC POLICY</h1>
          <p>If a consumer requests a copy of our “Do Not Call” policy, we will send a copy via U.S. mail.</p>
          <p>Consumers may request, by telephone or in writing, that our company stop calling them and place their name on
            a “Do Not Call” list. Although we may ask for your name, address and telephone number, we will accept your
            request even if you only provide your telephone number. You have a right to have calls stopped and to be put
            on our “Do Not Call” list even if you are still a customer.</p>
          <p>When we receive your request, your telephone number is added to our “Do Not Call” list within 2 business
            days. In some cases it may take up to 2 business days for your telephone number to be removed from other
            company wide calling lists. Your request will stay on our list for at least 5 years. If you move, change your
            telephone number, or add an additional telephone number, you must provide us with the new telephone number in
            order for us to prevent calls to that number. We will not share the information you provide with anyone except
            affiliated companies or subsidiaries without your prior written permission.</p>
          <p>We may perform telemarketing services for other companies. In this case, we will also forward our updated “Do
            Not Call” list(s) to these companies on a periodic basis, unless the client requests otherwise or refuses to
            accept these updates from us.</p>
          <p>All employees that engage in outbound telephone solicitation are trained in this policy and made aware of
            these procedures before they are allowed to place calls to consumers. Managers, supervisors, or trainers
            review the policy with these employees periodically.</p>
          <p>If the consumer notifies us that they have received a telephone call from us after they have already
            requested to be placed on our “Do Not Call” list, we will apologize for the intrusion and have a manager or
            supervisor investigate. The manager or supervisor will also personally confirm that the telephone number is on
            the “Do Not Call” list. Upon request, we will mail the consumer a confirmation that their telephone number is
            on the list.</p>
          <p>The Federal Trade Commission offers a free service to consumers that allows them to place their residential
            phone number on a National Do Not Call Registry. This service will reduce the number of telemarketing calls
            received. Consumers may add their residential phone number to the list either by calling 888-382-1222 or
            through the Internet at www.donotcall.gov. It may take several weeks before you notice a reduction in calls.
          </p>
        </div>
      </el-container>
    </el-main>
  </div>
</template>

<script>
import { Container } from "element-ui";
Vue.use(Container);
export default {
  name: 'DncPolicy',
  components: {
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";

@import "../assets/scss/text.scss";
</style>
